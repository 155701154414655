
.project-container {
    display: flex;
    flex-direction: column;
    padding: 20px 70px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .practical-details{
        height: calc(100% - 55px);
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: #fff;
    }
}
.project-box {
    width: 1200px;
    margin: 0 auto;
}
